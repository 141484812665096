




































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
@Component
export default class PasswordComponent extends Vue {
  content = "";
  onePercentage = 0;
  twoPercentage = 0;
  ThreePercentage = 0;
  oneCustomColors = [{ color: "#f56c6c", percentage: 100 }];
  twoCustomColors = [{ color: "#e6a23c", percentage: 100 }];
  ThreeCustomColors = [{ color: "#67c23a", percentage: 100 }];

  @Prop() password!: boolean;

  format() {
    return "";
  }

  @Watch("password")
  handleWatch(value: string) {
    const mode = this.checkPasswordStrength(value);
    //级别处理
    switch (
      mode //初始化状态
    ) {
      case 0:
        this.content = "";
        this.onePercentage = 0;
        this.twoPercentage = 0;
        this.ThreePercentage = 0;
        break;
      case 2:
        this.content = "弱";
        this.onePercentage = 100;
        this.twoPercentage = 0;
        this.ThreePercentage = 0;
        break;
      case 3:
        this.content = "中";
        this.onePercentage = 100;
        this.twoPercentage = 100;
        this.ThreePercentage = 0;
        break;
      case 4:
        this.content = "强";
        this.onePercentage = 100;
        this.twoPercentage = 100;
        this.ThreePercentage = 100;
        break;
      default:
        this.content = "";
        this.onePercentage = 0;
        this.twoPercentage = 0;
        this.ThreePercentage = 0;
        break;
    }
  }

  //密码强度验证
  checkPasswordStrength(value: string) {
    let mode = 0;
    const reg: any = new RegExp(
      "^[a-z0-9A-Z.`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]*$"
    ); // 只允许大小写字母 数字 特殊字符
    //正则表达式验证符合要求的
    if (value.length < 6) return mode;
    if (!reg.test(value)) return mode;
    if (/\d/.test(value)) mode++; //数字
    if (/[a-z]/.test(value)) mode++; //小写
    if (/[A-Z]/.test(value)) mode++; //大写
    if (/\W/.test(value)) mode++;
    //特殊字符
    console.log(mode);
    return mode;
  }
}
