
























































import { Component, Vue } from "vue-property-decorator";
import { ApiPostDoReset } from "@/apis";
import PasswordComponent from "../components/password/index.vue";
import { Form } from "element-ui";
import * as utils from "@/utils";
@Component({
  components: {
    PasswordComponent,
  },
})
export default class ResetPassword extends Vue {
  ruleForm = {
    newPassword: "",
    checkPassword: "",
    oldPassword: "",
  };
  validatePass = (rule: any, str: string, callback: Function) => {
    const reg: any = new RegExp(
      "^(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?!`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？)\\S+$"
    ); // 大小写字母 数字 特殊字符其中一种
    const reg2: any = new RegExp(
      "^[a-z0-9A-Z.`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]*$"
    ); // 只允许大小写字母 数字 特殊字符其中一种

    let mode = 0;
    //正则表达式验证符合要求的
    if (/\d/.test(str)) mode++; //数字
    if (/[a-z]/.test(str)) mode++; //小写
    if (/[A-Z]/.test(str)) mode++; //大写
    if (/\W/.test(str)) mode++;
    if (this.ruleForm.newPassword !== "") {
      if (
        this.ruleForm.newPassword.length <= 5 ||
        this.ruleForm.newPassword.length >= 100
      ) {
        callback(new Error("密码长度不符合"));
      } else if (!reg.test(str)) {
        callback(new Error("密码不符合要求"));
      } else if (mode <= 1) {
        callback(new Error("密码不符合要求"));
      } else if (reg2.test(str)) {
        callback();
      } else {
        callback(new Error("密码不符合要求"));
      }
    } else {
      callback(new Error("请输入新密码"));
    }
  };
  validatePass2 = (rule: any, str: string, callback: Function) => {
    if (this.ruleForm.checkPassword !== "") {
      if (this.ruleForm.checkPassword === this.ruleForm.newPassword) {
        callback();
      } else {
        callback(new Error("两次输入不一致"));
      }
    } else {
      callback(new Error("请输入确认密码"));
    }
  };
  rules = {
    oldPassword: [
      { required: true, message: "请输入原始密码", trigger: "blur" },
    ],
    newPassword: [
      {
        required: true,
        validator: this.validatePass,
        trigger: "blur",
      },
    ],
    checkPassword: [
      {
        required: true,
        validator: this.validatePass2,
        trigger: "blur",
      },
    ],
  };
  async submitForm() {
    (this.$refs.ruleForm as Form).validate(async (valid) => {
      if (valid) {
        const oldPwd = await utils.encrypt(this.ruleForm.oldPassword);
        const newPwd = await utils.encrypt(this.ruleForm.newPassword);
        ApiPostDoReset(oldPwd, newPwd).then((res) => {
          if (res.success) {
            this.ruleForm.newPassword = "";
            this.ruleForm.checkPassword = "";
            this.ruleForm.oldPassword = "";
            this.$message({
              message: "修改密码成功",
              type: "success",
            });
          }
        });
      } else {
        return false;
      }
    });
  }
}
